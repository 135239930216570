import { LoginModal } from '../vpsb/login_modal';

$(document).on('turbo:load', function() {
  if ($("body.products").length == 0) {
    return;
  }
  console.log("page load in product.js");

  $('.order-product-form').on('submit', (event) => {

    var loggedIn = $('.btn-login-modal').data('logged-in');
    if (loggedIn) {
      console.log("Skipping login modal");
      return;
    }

    event.preventDefault();

    var formElt = event.currentTarget;
    var loginModal = new LoginModal(formElt, null, {});
    loginModal.load($('.btn-login-modal').data('label'));

    // event.preventDefault();
    // show_layout_modal('We saved your order. Please sign in or sign up on the next page to check out.');
    // var formElt = event.currentTarget;
    // $('#layout-modal').on('hide.bs.modal', () => {
    //   $(formElt).submit();
    // });
  });
});
