import { LoginModal } from '../vpsb/login_modal';
import VChartsLoader from './lib/vcharts_loader.js';
import { showEphemeral, show_layout_modal, amIABot } from './global';

class AccountsIntelligencePage {
  constructor() {
    this.vchartsLoader = new VChartsLoader();
    this.chatTimerId = null;
  }

  init() {
    this.setupPromptInput();
    this.loadCharts()
    this.setupHelpMessageCross();
    this.setupDestroyButtons();
    this.askFirstQuestion();
    $('textarea#prompt-input').trigger('focus');
  }

  askFirstQuestion() {
    if (amIABot()) {
      console.debug("Bots don't ask questions");
      return;
    }

    const question = $('div#question').data('first-question');
    if (question && question.trim() !== "") {
      $('textarea#prompt-input').val(question);
      const url = $('a#prompt-button').attr('href');
      this.submitPrompt(url);
    }
  }

  setupHelpMessageCross() {
    $('.close-cross').on('click', (e) => {
      $(e.currentTarget).parent().hide();
      localStorage.setItem('hide_help_message', true);
    });

    if (localStorage.getItem('hide_help_message') == 'true') {
      $('div.help-message').hide();
    }
  }

  setupPromptInput() {
    $('a#prompt-button').on('click', (e) => {
      e.preventDefault();
      var url = $(e.currentTarget).attr('href');
      this.submitPrompt(url);
    });

    $('textarea#prompt-input').on('keydown', (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        var url = $('a#prompt-button').attr('href');
        this.submitPrompt(url);
      }
    });
  }

  loadCharts() {
    $('canvas.model-chart-canvas').each((index, canvas) => {
      var chartIndex = $(canvas).data('chart-index');
      this.loadChart(chartIndex);
    });
  }

  loadChart(chartIndex) {
    console.log(`loading chart ${chartIndex}`);
    var chartJsonScript = `script#chartjs-json-${chartIndex}`;
    var chartJson = $(chartJsonScript).text();
    var canvasElt = $(`canvas#canvas-${chartIndex}`);

    console.debug(chartJson);

    var ctx = $(canvasElt).get(0).getContext("2d");
    this.vchartsLoader.pass_through_chart(ctx, chartJson);
  }

  submitPrompt(url) {
    var meta_csrf = $('meta[name=csrf-token]');
    var csrfToken = meta_csrf.attr('content');
    var chatId = $('div#prompt-container').data('chat-id');
    var method = $('div#prompt-container').data('new-chat') == true ? 'POST' : 'PUT';
    var prompt = $('textarea#prompt-input').val();
    if (prompt.trim() == "") {
      console.error("Won't submit an empty prompt");
      return;
    }

    this.appendMessageRow('user', prompt);
    $('body').css('cursor', 'wait');
    $('textarea#prompt-input').prop('disabled', true);
    $('textarea#prompt-input').addClass('loading');
    $('div#prompt-container').data('chat-state', 'waiting');
    this.displayChatTimer();

    $.ajax({
      method: method,
      url: url,
      data: { chat_id: chatId, prompt: prompt },
      dataType: 'json',
      headers: { "X-CSRF-Token": csrfToken },
      success: (data) => {
        $('body').css('cursor', 'default');
        this.stopChatTimer();
        var json = data;
        console.debug(`${method} success ${json.message}`);
        this.appendMessageRow('assistant', json.message);
        var lastUserMessage = $('div#message-list-wrapper').find('div.user-message:last')[0];
        $('div#prompt-container').data('chat-id', json.chat_id);
        $('div#prompt-container').data('new-chat', false);
        $('div#prompt-container').data('chat-state', 'ready');
        $('textarea#prompt-input').val("");
        $('textarea#prompt-input').prop('disabled', false);
        $('textarea#prompt-input').removeClass('loading');
        $('textarea#prompt-input').trigger('focus');

        $('a#prompt-button').attr('href', json.chat_path);
        $('span#llm_tokens_credit').text(json.llm_tokens_credit);
        $('span#chat_tokens_used').text(json.chat_tokens_used);

        if (json.chart_index != null) {
          this.loadChart(json.chart_index);
        }

        $('#chat-title').text(json.chat_title);
        document.title = json.chat_title;
        this.scrollToElementWithOffset(lastUserMessage, 100);

        if (method == 'POST') {
          history.pushState({}, '', json.chat_path + '/edit');
        }
      },
      error: (data) => {
        $('body').css('cursor', 'default');
        this.stopChatTimer();
        var json = data.responseJSON;
        var err_msgs;
        if (typeof json !== 'undefined') {
          err_msgs = json.errors;
          if (json.status == 'sign_in') {
            var loginModal = new LoginModal(null, json.chat_signed_in_url,
                  { show_skip_button: true, skip_button_label: "Start new chat", skip_url: "/intelligence_chats/new" });
            loginModal.load(err_msgs[0]);
            $('textarea#prompt-input').prop('disabled', false);
            $('textarea#prompt-input').removeClass('loading');
            return;
          }
        } else {
          err_msgs = ["An error occurred while processing your request."];
        }

        console.error(`err: ${err_msgs}`, `code: ${data.status}`);
        show_layout_modal(err_msgs.join(' - '));
        $('div#message-list-wrapper').find('div.user-message:last').remove();
        $('textarea#prompt-input').prop('disabled', false);
        $('textarea#prompt-input').removeClass('loading');
        $('textarea#prompt-input').trigger('focus');
        $('div#prompt-container').data('chat-state', 'error');
      }
    });
  }

  displayChatTimer() {
    var elapsedTime = 0;
    this.chatTimerId = setInterval(() => {
      elapsedTime += 0.1;

      $('span#chat-timer').text(`${elapsedTime.toFixed(1)}s`);
    }, 100);
  }

  stopChatTimer() {
    clearInterval(this.chatTimerId);
    this.chatTimerId = null;
  }

  appendMessageRow(role, message) {
    const row = `<div class="${role}-message">${message}</div>`;
    $('div#message-list-wrapper').append(row);
  }

  setupDestroyButtons() {
    $('table#chat-index').on('click', '.destroy-button', (event) => {
      event.preventDefault();
      var chatRow = $(event.currentTarget).parents('tr.chat-tr');
      var itemId = chatRow.data('chat-id');
      $.ajax({
        method: "DELETE",
        url: `/accounts/intelligence_chats/${itemId}`,
        dataType: 'json',
        success: function() {
          console.log("delete success");
          showEphemeral("This conversation was deleted successfully");
          chatRow.remove();
        },
        error: (data) => {
          var err_msgs;
	        var json = data.responseJSON;
          if (typeof json !== 'undefined')
            err_msgs = json.errors;
          else
            err_msgs = ["An error occurred while deleting this chat."];

          console.error(`err: ${err_msgs}`, `code: ${data.status}`);
          show_layout_modal(err_msgs.join(' - '));
        }
      });
    });
  }

  scrollToElementWithOffset(element, offset) {
    if ($(element).length) {
      $('html, body').animate({
        scrollTop: $(element).offset().top - offset
      }, 1000);
    }
  }
}

$(document).on('turbo:load', function() {
  if ($("body.accounts_intelligence,body.llm_chats").length == 0) {
    return;
  }
  console.log("page load in accounts_intelligence.js");

  var page = new AccountsIntelligencePage();
  page.init();
} );
