export default class VChartsLoader {
  constructor() {
  }

  init() {
    this.promise = this.load_chartjs_async();
  }

  load_chartjs_async() {
    return this.chartjs_promise().then( (vcharts) => {
      return new vcharts.default();
    });
  }

  chartjs_promise() {
    return new Promise((resolve) => {
      import(/* webpackChunkName: "chartjs" */ './vcharts.js').then( (vcharts) => {
        console.debug('importing chart.js');
        resolve(vcharts);
      })
    });
  }

  get_promise() {
    this.promise = (this.promise || this.load_chartjs_async());
    return this.promise;
  }

  load_chart(ctx, config) {
    this.get_promise().then( (vcharts) => {
      vcharts.load_chart(ctx,config);
    });
  }

  vpsb_bar_chart(ctx, canvas_id, barNames, values, unit, label, direction, display_legend, minValue, barThickness) {
    this.get_promise().then( (vcharts) => {
      vcharts.vpsb_bar_chart(ctx, canvas_id, barNames, values, unit, label, direction, display_legend, minValue, barThickness);
    });
  }

  vpsb_bar_chart_improved(ctx, data) {
    this.get_promise().then( (vcharts) => {
      vcharts.vpsb_bar_chart_improved(ctx, data);
    });
  }

  vpsb_line_chart_dual_axis(ctx, data) {
    this.get_promise().then( (vcharts) => {
      vcharts.vpsb_line_chart_dual_axis(ctx, data);
    });
  }

  vpsb_stacked_bar_chart(ctx, data) {
    this.get_promise().then( (vcharts) => {
      vcharts.vpsb_stacked_bar_chart(ctx, data);
    });
  }

  vpsb_scatter_chart(ctx, data, id) {
    this.get_promise().then( (vcharts) => {
      vcharts.vpsb_scatter_chart(ctx, data, id);
    });
  }

  vpsb_polar_chart(ctx, data, canvas) {
    this.get_promise().then( (vcharts) => {
      vcharts.vpsb_polar_chart(ctx, data, canvas);
    });
  }

  simple_chart(ctx, type, data, options = {}) {
    this.get_promise().then( (vcharts) => {
      vcharts.simple_chart(ctx, type, data, options);
    });
  }

  simple_pie_chart(ctx, data, options = {}) {
    this.get_promise().then( (vcharts) => {
      vcharts.simple_pie_chart(ctx, data, options);
    });
  }

  pass_through_chart(ctx, data) {
    this.get_promise().then( (vcharts) => {
      vcharts.pass_through_chart(ctx, data);
    });
  }
}
